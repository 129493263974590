//运营数据区，街道查询数据
const initTenantQxUrl = `/gateway/hc-serve/manageapi/operationStatistics/initTenantQx`;
//获取验收批次的下拉框
const initYspcUrl = `/gateway/hc-serve/manageapi/operationStatistics/initYspc`;
// 查询 - 运营数据 - 列表
const getYysjListUrl = `/gateway/hc-serve/manageapi/operationStatistics/getSinglePointStatistics`;

// 查询 - 运营数据 - 未来社区选项
// const getYysjWlsqmcOptUrl = `/gateway/hc-serve/manageapi/operationStatistics/getWlsqTenantInfo`;
const getYysjWlsqmcOptUrl = `/gateway/hc-serve/manageapi/operationStatistics/init`;

// 导出 - 运营数据
const exportYysjUrl = `/gateway/hc-serve/manageapi/operationStatistics/exportSinglePointStatistics`;

// 获取运营看板数据
const getSinglePointStatistics =
  "/gateway/hc-serve/manageapi/operationStatistics/getSingleStatistics";

// 获取商品兑换分析列表
const getGoodsExchangeDetails =
  "/gateway/hc-serve/manageapi/operationStatistics/getGoodsExchangeDetails";

// 近30日应用点击排行
const getDjphList =
  "/gateway/hc-serve/manageapi/operationStatistics/getFirstYydjphDetails";

// 获取活动统计数据
const getActivityDetails =
  "/gateway/hc-serve/manageapi/operationStatistics/getActivityDetails";

// 可预约空间数
const getCanPlaceOrder =
  "/gateway/hc-serve/manageapi/operationStatistics/getCanPlaceOrder";

// 空间预约次数排行
const getPlaceOrderPlThree = `/gateway/hc-serve/manageapi/operationStatistics/getPlaceOrderPlThree`;

// 核销率
const getJfddhxl = "/gateway/hc-serve/manageapi/operationStatistics/getJfddhxl";

// 消费率
const getPointXfl =
  "/gateway/hc-serve/manageapi/operationStatistics/getPointXfl";

// 户均人口数
const getHjrks = "/gateway/hc-serve/manageapi/operationStatistics/getHjrks";
// 社区画像
const getSqhxDetails =
  "/gateway/hc-serve/manageapi/operationStatistics/getSqhxDetails";
// 获取 - 新增人数 - 折线图数据
const getAddPeopleNumberUrl = `/gateway/hc-serve/manageapi/operationStatistics/getNewAddPersonData`;

// 获取 - 新增人数 - 折线图数据
const getActivePeopleNumberUrl = `/gateway/hc-serve/manageapi/operationStatistics/getHyPersons`;

// 获取 - 近30日日活统计 - 折线图数据
const getJingThirtyDayActiveUrl = `/gateway/hc-serve/manageapi/operationStatistics/getDailyActivityDetails`;

// 获取 - 空间预约次数 - 折线图数据
const getKjyycsUrl = `/gateway/hc-serve/manageapi/operationStatistics/getPlaceOrderDetails`;

//区级运营看板
const getSingleStatisticsDistrictUrl = `/gateway/hc-serve/manageapi/operationStatisticsDistrict/getSingleStatisticsDistrict`;
//获取新增人数数据
const getNewAddPersonDataDistrictUrl = `/gateway/hc-serve/manageapi/operationStatisticsDistrict/getNewAddPersonDataDistrict`;
//获取区级30日活跃度
const getHyPersonsDistrictUrl = `/gateway/hc-serve/manageapi/operationStatisticsDistrict/getHyPersonsDistrict`;
//获取社区下拉
const getWlsqTenantInfoUrl = `/gateway/hc-serve/manageapi/operationStatistics/getWlsqTenantInfo`;
//获取近30日日活统计详细数据
const getDailyActivityDetailsDistrictUrl = `/gateway/hc-serve/manageapi/operationStatisticsDistrict/getDailyActivityDetailsDistrict`;
//区级积分订单核销率
const getJfddhxlDistrictUrl = `/gateway/hc-serve/manageapi/operationStatisticsDistrict/getJfddhxlDistrict`;
//区级积分消费率
const getPointXflDistrictUrl = `/gateway/hc-serve/manageapi/operationStatisticsDistrict/getPointXflDistrict`;
//区级活动统计数据
const getActivityDetailsDistrictUrl = `/gateway/hc-serve/manageapi/operationStatisticsDistrict/getActivityDetailsDistrict`;
//区级可预约空间数
const getCanPlaceOrderDistrictUrl = `/gateway/hc-serve/manageapi/operationStatisticsDistrict/getCanPlaceOrderDistrict`;
//区级空间预约数详情
const getPlaceOrderDetailsDistrictUrl = `/gateway/hc-serve/manageapi/operationStatisticsDistrict/getPlaceOrderDetailsDistrict`;
//区级空间预约次数排行
const getPlaceOrderPlFiveDistrictUrl = `/gateway/hc-serve/manageapi/operationStatisticsDistrict/getPlaceOrderPlFiveDistrict`;
//设备在线
const getDeviceOnlinelDistrictUrl = `/gateway/hc-serve/manageapi/operationStatisticsDistrict/getDeviceOnlinelDistrict`;
//获取近30日应用点击排行（前五）
const getFirstYydjphDetailsDistrictUrl = `/gateway/hc-serve/manageapi/operationStatisticsDistrict/getFirstYydjphDetailsDistrict`;
//区级社区画像
const getSqhxDetailsDistrictUrl = `/gateway/hc-serve/manageapi/operationStatisticsDistrict/getSqhxDetailsDistrict`;
export {
  initYspcUrl,
  initTenantQxUrl,
  getYysjListUrl,
  getSqhxDetailsDistrictUrl,
  getDeviceOnlinelDistrictUrl,
  getFirstYydjphDetailsDistrictUrl,
  getPlaceOrderPlFiveDistrictUrl,
  getPlaceOrderDetailsDistrictUrl,
  getCanPlaceOrderDistrictUrl,
  getActivityDetailsDistrictUrl,
  getPointXflDistrictUrl,
  getJfddhxlDistrictUrl,
  getWlsqTenantInfoUrl,
  getDailyActivityDetailsDistrictUrl,
  getHyPersonsDistrictUrl,
  getNewAddPersonDataDistrictUrl,
  getSingleStatisticsDistrictUrl,
  getYysjWlsqmcOptUrl,
  exportYysjUrl,
  getSinglePointStatistics,
  getGoodsExchangeDetails,
  getDjphList,
  getActivityDetails,
  getCanPlaceOrder,
  getPlaceOrderPlThree,
  getJfddhxl,
  getPointXfl,
  getAddPeopleNumberUrl,
  getActivePeopleNumberUrl,
  getJingThirtyDayActiveUrl,
  getKjyycsUrl,
  getSqhxDetails,
  getHjrks,
};
