import * as echarts from "echarts";

import "echarts-liquidfill";
import { values } from "lodash";
// 社区画像 排名图
export function drawCommunityPm(eleId, seriesData, max) {
  let chartData = seriesData.map((item) => {
    return item.count;
  });
  let chartName = [
    "3岁及以下",
    "4-17岁",
    "18-59岁",
    "60-69岁",
    "70-79岁",
    "80岁以上",
  ];
  let yData = [];
  for (var i = 0; i < chartName.length; i++) {
    yData.push(i + "," + chartName[i]);
  }
  let option = {
    tooltip: {
      trigger: "axis",
      borderColor: "#62FFFF",
      formatter: " {b}：     {c}人",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "0",
      right: "15%",
      bottom: "2%",
      top: "2%",
      containLabel: true,
    },
    xAxis: [
      {
        show: false,
      },
      {
        show: false,
      },
    ],
    yAxis: {
      type: "category",
      inverse: true,
      nameGap: 16,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
        margin: 20,
        textStyle: {
          color: "#d1e6eb",
        },
      },
      data: yData,
    },

    series: [
      //亮色条 百分比
      {
        show: true,
        type: "bar",
        barGap: "-100%",
        barWidth: 10,
        z: 2,
        silent: true,
        itemStyle: {
          barBorderRadius: 10,
          color: "#2063FF",
        },
        data: chartData,
      },
      {
        name: "外框",
        type: "bar",
        itemStyle: {
          normal: {
            barBorderRadius: 10,
            color: "#F3F6F8", //rgba设置透明度0.14
          },
        },
        label: {
          normal: {
            show: true,
            textStyle: {
              color: "#DEDEDE",
              fontSize: 14,
            },

            formatter: function (data) {
              return "";
            },
            rich: {
              blue: {
                fontSize: 14,
                lineHeight: 5,
                color: "#62E6F6",
              },
            },
          },
        },
        barGap: "-100%",
        z: 0,
        barWidth: 10,
        data: [max, max, max, max, max, max, max],
      },
      //年份
      {
        show: true,
        type: "bar",
        xAxisIndex: 1, //代表使用第二个X轴刻度
        barGap: "-100%",
        barWidth: 10,
        itemStyle: {
          normal: {
            barBorderRadius: 0,
            color: "transparent",
          },
        },
        label: {
          normal: {
            show: true,
            position: [0, "-16"],
            textStyle: {
              fontSize: 14,
              color: "#868686",
            },
            formatter: function (data) {
              var result = "";
              result += chartName[data.dataIndex];
              return result;
            },
            rich: {
              blue: {
                fontSize: 14,
                lineHeight: 5,
                color: "#62E6F6",
              },
              mr: {
                padding: [0, 0, 0, 10],
                fontSize: 14,
                color: "#fff",
              },
            },
          },
        },
        data: chartData,
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
// 社区画像 性别图
export function drawCommunityGender(eleId, seriesData) {
  console.log("seriesData => ", seriesData);
  const sum = seriesData
    .map((item) => item.value)
    .reduce((prev, next) => prev + next, 0);
  var symbols = [
    "path://M18.2629891,11.7131596 L6.8091608,11.7131596 C1.6685112,11.7131596 0,13.032145 0,18.6237673 L0,34.9928467 C0,38.1719847 4.28388932,38.1719847 4.28388932,34.9928467 L4.65591984,20.0216948 L5.74941883,20.0216948 L5.74941883,61.000787 C5.74941883,65.2508314 11.5891201,65.1268798 11.5891201,61.000787 L11.9611506,37.2137775 L13.1110872,37.2137775 L13.4831177,61.000787 C13.4831177,65.1268798 19.3114787,65.2508314 19.3114787,61.000787 L19.3114787,20.0216948 L20.4162301,20.0216948 L20.7882606,34.9928467 C20.7882606,38.1719847 25.0721499,38.1719847 25.0721499,34.9928467 L25.0721499,18.6237673 C25.0721499,13.032145 23.4038145,11.7131596 18.2629891,11.7131596 M12.5361629,1.11022302e-13 C15.4784742,1.11022302e-13 17.8684539,2.38997966 17.8684539,5.33237894 C17.8684539,8.27469031 15.4784742,10.66467 12.5361629,10.66467 C9.59376358,10.66467 7.20378392,8.27469031 7.20378392,5.33237894 C7.20378392,2.38997966 9.59376358,1.11022302e-13 12.5361629,1.11022302e-13",
    "path://M28.9624207,31.5315864 L24.4142575,16.4793596 C23.5227152,13.8063773 20.8817445,11.7111088 17.0107398,11.7111088 L12.112691,11.7111088 C8.24168636,11.7111088 5.60080331,13.8064652 4.70917331,16.4793596 L0.149791395,31.5315864 C-0.786976655,34.7595013 2.9373074,35.9147532 3.9192135,32.890727 L8.72689855,19.1296485 L9.2799493,19.1296485 C9.2799493,19.1296485 2.95992025,43.7750224 2.70031069,44.6924335 C2.56498417,45.1567684 2.74553639,45.4852068 3.24205501,45.4852068 L8.704461,45.4852068 L8.704461,61.6700801 C8.704461,64.9659872 13.625035,64.9659872 13.625035,61.6700801 L13.625035,45.360657 L15.5097899,45.360657 L15.4984835,61.6700801 C15.4984835,64.9659872 20.4191451,64.9659872 20.4191451,61.6700801 L20.4191451,45.4852068 L25.8814635,45.4852068 C26.3667633,45.4852068 26.5586219,45.1567684 26.4345142,44.6924335 C26.1636859,43.7750224 19.8436568,19.1296485 19.8436568,19.1296485 L20.3966199,19.1296485 L25.2043926,32.890727 C26.1862111,35.9147532 29.9105828,34.7595013 28.9625083,31.5315864 L28.9624207,31.5315864 Z M14.5617154,0 C17.4960397,0 19.8773132,2.3898427 19.8773132,5.33453001 C19.8773132,8.27930527 17.4960397,10.66906 14.5617154,10.66906 C11.6274788,10.66906 9.24611767,8.27930527 9.24611767,5.33453001 C9.24611767,2.3898427 11.6274788,0 14.5617154,0 L14.5617154,0 Z",
    "path://M512 292.205897c80.855572 0 146.358821-65.503248 146.358821-146.358821C658.358821 65.503248 592.855572 0 512 0 431.144428 0 365.641179 65.503248 365.641179 146.358821 365.641179 227.214393 431.144428 292.205897 512 292.205897zM512 731.282359c-80.855572 0-146.358821 65.503248-146.358821 146.358821 0 80.855572 65.503248 146.358821 146.358821 146.358821 80.855572 0 146.358821-65.503248 146.358821-146.358821C658.358821 796.273863 592.855572 731.282359 512 731.282359z",
  ];
  var bodyMax = 100; //指定图形界限的值
  var labelSetting = {
    normal: {
      show: true,
      position: "bottom",
      offset: [-12, 15],
      formatter: function (param) {
        return ((param.value / bodyMax) * 100).toFixed(0) + "人";
      },
      textStyle: {
        fontSize: 16,
        fontFamily: "Arial",
      },
    },
  };

  let option = {
    tooltip: {
      show: true,
      valueFormatter: (value) => {
        console.log("value =》", value);
        return +((value / sum) * 100).toFixed(2) + "%";
      },
      // trigger: "axis",
      // borderColor: "#62FFFF",
      // formatter: " {b}：     {c}人",
      // axisPointer: {
      //   type: "shadow",
      // },
    },
    color: ["#F27B7B", "#6FAAF7"],
    grid: {
      left: "40%",
      right: "40%",
      top: "15%",
      bottom: "45%",
      containLabel: true,
    },
    xAxis: {
      position: "top",
      data: ["男性", "女性"],
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        margin: 10,
        textStyle: {
          color: "#fff",
          fontSize: 14,
        },
      },
    },
    yAxis: {
      max: bodyMax,
      splitLine: {
        show: false,
      },
      axisTick: {
        // 刻度线
        show: false,
      },
      axisLine: {
        // 轴线
        show: false,
      },
      axisLabel: {
        // 轴坐标文字
        show: false,
      },
    },
    series: [
      {
        name: "男性",
        type: "pictorialBar",
        symbolClip: false,
        symbolBoundingData: bodyMax,
        label: labelSetting,
        data: [
          {
            value: seriesData.find((item) => {
              return item.name === "男";
            }).value,
            symbol: symbols[0],
            symbolSize: [40, 80],
            symbolOffset: [-15, 0],
            itemStyle: {
              normal: {
                color: "#6FAAF7", //单独控制颜色
                // color: 'transparent'
              },
            },
          },
          {},
        ],
        // markLine: markLineSetting,
        z: 10,
        tooltip: {
          show: false,
        },
      },
      {
        name: "女性",
        type: "pictorialBar",
        symbolClip: false,
        symbolBoundingData: bodyMax,
        label: {
          normal: {
            show: true,
            position: "bottom",
            offset: [20, 15],
            formatter: function (param) {
              return ((param.value / bodyMax) * 100).toFixed(0) + "人";
            },
            textStyle: {
              fontSize: 16,
              fontFamily: "Arial",
            },
          },
        },
        data: [
          {},
          {
            value: seriesData.find((item) => {
              return item.name === "女";
            }).value,
            symbol: symbols[1],
            symbolSize: [42, 80],
            symbolOffset: [14, 0],
            itemStyle: {
              normal: {
                color: "#F27B7B", //单独控制颜色
              },
            },
          },
        ],
        // markLine: markLineSetting,
        z: 10,
        tooltip: {
          show: false,
        },
      },
      // {
      //   // 设置背景底色，不同的情况用这个
      //   name: "full",
      //   type: "pictorialBar", //异型柱状图 图片、SVG PathData
      //   symbolBoundingData: bodyMax,
      //   animationDuration: 0,
      //   itemStyle: {
      //     normal: {
      //       color: "#ccc", //设置全部颜色，统一设置
      //     },
      //   },
      //   z: 10,
      //   data: [
      //     {
      //       itemStyle: {
      //         normal: {
      //           color: "rgba(105,204,230,0.40)", //单独控制颜色
      //         },
      //       },
      //       value: 100,
      //       symbol: symbols[0],
      //     },
      //     {
      //       itemStyle: {
      //         normal: {
      //           color: "rgba(255,130,130,0.40)", //单独控制颜色
      //         },
      //       },
      //       value: 100,
      //       symbol: symbols[1],
      //     },
      //   ],
      // },
      {
        name: "男女比例",
        type: "pie",
        radius: ["75%", "95%"],
        center: ["50%", "50%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: false,
            textStyle: {
              fontSize: "30",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        // data: [
        //   { value: 5054, name: "男性" },
        //   { value: 70373, name: "女性" },
        // ],
        data: seriesData,
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
// 人房
export function drawRfPie(eleId, value, title, color) {
  let option = {
    title: title,
    polar: {
      radius: ["20%", "50%"],
      center: ["50%", "30%"],
    },
    angleAxis: {
      max: 100,
      show: false,
    },
    radiusAxis: {
      type: "category",
      show: true,
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        type: "pie",
        startAngle: 0,
        radius: ["60%", "80%"],
        center: ["50%", "40%"],
        data: [
          {
            hoverOffset: 1,
            value: value,
            name: "",
            itemStyle: {
              normal: {
                color: color,
              },
            },
            label: {
              show: false,
            },
            labelLine: {
              normal: {
                smooth: true,
                lineStyle: {
                  width: 0,
                },
              },
            },
            hoverAnimation: false,
          },
          {
            itemStyle: {
              normal: {
                color: "#F2FAFF",
              },
            },
            labelLine: {
              normal: {
                smooth: true,
                lineStyle: {
                  width: 0,
                },
              },
            },
            value: 100 - value,
            hoverAnimation: false,
          },
        ],
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}

// 居民端使用人数 饼图
export function drawUseNumberChart(eleId, seriesData) {
  let option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      bottom: "2%",
      left: "center",
      orient: "vertical",
      textStyle: {
        rich: {
          c: {
            color: "rgba(0,0,0,0.85)",
            fontSize: 16,
          },
          d: {
            color: "rgba(0,0,0,0.5)",
            fontSize: 16,
            marginRight: 10,
            marginLeft: 10,
          },
          e: {
            color: "rgba(0,0,0,0.5)",
            fontSize: 16,
          },
        },
      },
      formatter: (name) => {
        let data = seriesData.find((item) => {
          return item.name === name;
        });

        return `  {c|${name}}      {d|${data.value}}      {e|${
          (data.value / data.sum).toFixed(2) * 100
        }%}`;
      },
    },
    series: [
      {
        name: "",
        type: "pie",
        radius: ["35%", "65%"],
        center: ["50%", "40%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        // emphasis: {
        //   label: {
        //     show: true,
        //     fontSize: 40,
        //     fontWeight: "bold",
        //   },
        // },
        labelLine: {
          show: false,
        },
        data: seriesData,
        itemStyle: {
          normal: {
            color: function (colors) {
              var colorList = ["#53DAAB", "#FFCE6F"];
              return colorList[colors.dataIndex];
            },
          },
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
// 新增人数
export function drawAddPeopleChart(eleId, seriesData) {
  let option = {
    color: ["#2063FF", "#E04545"],
    grid: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 0,
      containLabel: true,
    },
    tooltip: {
      show: true,
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgba(0,0,0,0.5)",
        },
      },
      axisTick: {
        inside: true,
        lineStyle: {
          color: "rgba(0,0,0,0.5)",
        },
      },
      data: seriesData.map((item) => item.name),
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgba(0,0,0,0.4)",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "rgba(0,0,0,0.1)",
          type: "dashed",
        },
      },
    },
    series: [
      {
        type: "line",
        name: "新增注册人数",
        smooth: true,
        symbol: "none",
        data: seriesData.map((item) => item.zcrs),
        tooltip: {
          valueFormatter: (value) => `${value}人`,
        },
      },
      {
        type: "line",
        name: "新增游客人数",
        smooth: true,
        symbol: "none",
        data: seriesData.map((item) => item.ykrs),
        tooltip: {
          valueFormatter: (value) => `${value}人`,
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}

// 活跃人数折线图
export function drawHyrsLine(eleId, seriesData) {
  let option = {
    color: ["#2063FF", "#E04545"],
    grid: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 10,
      containLabel: true,
    },
    tooltip: {
      show: true,
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgba(0,0,0,0.5)",
        },
      },
      axisTick: {
        inside: true,
        lineStyle: {
          color: "rgba(0,0,0,0.5)",
        },
      },
      data: seriesData.map((item) => item.name),
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgba(0,0,0,0.4)",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "rgba(0,0,0,0.1)",
          type: "dashed",
        },
      },
    },
    series: [
      {
        type: "line",
        name: "活跃人数",
        smooth: true,
        symbol: "none",
        data: seriesData.map((item) => item.count),
        itemStyle: {
          color: "rgba(83, 218, 171, 1)",
        },
        tooltip: {
          valueFormatter: (value) => `${value}人`,
        },
      },
      // {
      //   type: 'line',
      //   name: '新增游客人数',
      //   smooth: true,
      //   symbol: 'none',
      //   data: seriesData.map(item => item.ykrs),
      //   tooltip: {
      //     valueFormatter: value => `${value}人`
      //   }
      // }
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
// 活跃人数  近30日事件完成率
export function drawCommonPie(eleId, value, title) {
  let option = {
    title: title,
    polar: {
      radius: ["40%", "72%"],
      center: ["50%", "46%"],
    },
    angleAxis: {
      max: 100,
      show: false,
    },
    radiusAxis: {
      type: "category",
      show: false,
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        name: "",
        type: "bar",
        roundCap: true,
        barWidth: 100,
        showBackground: true,
        backgroundStyle: {
          color: "#DFEEFF",
        },
        data: [value],
        coordinateSystem: "polar",
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: "#B3DDD1",
              },
              {
                offset: 1,
                color: "#95BDFF",
              },
            ]),
          },
        },
      },
      {
        name: "",
        type: "pie",
        startAngle: 80,
        radius: ["90%", "90%"],
        hoverAnimation: false,
        center: ["50%", "46%"],
        itemStyle: {
          color: "rgba(66, 66, 66, .1)",
          borderWidth: 1,
          borderColor: "#B3DDD1",
          borderType: "dashed",
        },
        data: [100],
        labelLine: {
          show: false,
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}

// 沉默率饼图
export function drawSilentPie(eleId, value, cmrs, title) {
  value = Math.abs(value);
  cmrs = Math.abs(cmrs);
  let option = {
    color: ["#F88082", "#0A67FF"],
    title: title,
    tooltip: {
      show: false,
      trigger: "item",
    },
    polar: {
      radius: ["40%", "72%"],
      center: ["50%", "46%"],
    },
    angleAxis: {
      max: 100,
      show: false,
    },
    radiusAxis: {
      type: "category",
      show: false,
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    legend: {
      bottom: "2%",
      left: "center",
      orient: "vertical",
      textStyle: {
        rich: {
          c: {
            color: "rgba(0,0,0,0.85)",
            fontSize: 16,
          },
          d: {
            color: "rgba(0,0,0,0.5)",
            fontSize: 16,
            marginRight: 10,
            marginLeft: 10,
          },
          e: {
            color: "rgba(0,0,0,0.5)",
            fontSize: 16,
          },
        },
      },
      data: ["沉默率"],
      formatter: (name) => {
        if (name === "沉默率") {
          return `{c|${name}}          {d|${value}%}`;
        } else {
          return `{c|${name}}      {d|${cmrs}人}`;
        }
      },
    },
    series: [
      {
        name: "沉默率",
        type: "bar",
        roundCap: false,
        barWidth: 100,
        showBackground: true,
        backgroundStyle: {
          color: "#DFEEFF",
        },
        data: [value],
        coordinateSystem: "polar",
        itemStyle: {
          normal: {
            color: "#F88082",
          },
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}

// 活跃人数折线图
export function drawRhtjLine(eleId, seriesData, pjsl) {
  // const averageNum = {
  //   点击量: (
  //     seriesData
  //       .map((item) => +item.djl)
  //       .reduce((prev, next) => prev + next, 0) / seriesData.length
  //   ).toFixed(2),
  //   日活人数: (
  //     seriesData
  //       .map((item) => +item.rhrs)
  //       .reduce((prev, next) => prev + next, 0) / seriesData.length
  //   ).toFixed(2),
  // };
  const averageNum = {
    点击量: pjsl.thirtyPjdjl,
    日活人数: pjsl.thirtyPjrh,
  };
  const unit = {
    点击量: "次",
    日活人数: "人",
  };
  let option = {
    color: ["#FFCE6F", "#A099FF"],
    legend: {
      top: -34,
      selectedMode: false,
      icon: "circle",
      itemGap: 30,
      textStyle: {
        color: "inherit",
        fontSize: 22,
        lineHeight: 30,
        padding: [30, 0, 0, 0],
        rich: {
          col: {
            color: "inherit",
            fontSize: 22,
          },
          c: {
            color: "#000",
            fontSize: 22,
          },
        },
      },
      formatter: (name) => {
        return [
          `{c|近30日平均${name}}`,
          `{col|${averageNum[name]}${unit[name]}}`,
        ].join("\n");
      },
      data: ["点击量", "日活人数"],
    },
    grid: {
      top: 70,
      right: 20,
      bottom: 20,
      left: 0,
      containLabel: true,
    },
    tooltip: {
      show: true,
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgba(0,0,0,0.5)",
        },
      },
      axisTick: {
        inside: true,
        lineStyle: {
          color: "rgba(0,0,0,0.5)",
        },
      },
      data: seriesData.map((item) => item.name),
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgba(0,0,0,0.4)",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "rgba(0,0,0,0.1)",
          type: "dashed",
        },
      },
    },
    series: [
      {
        type: "line",
        name: "点击量",
        smooth: true,
        symbol: "none",
        data: seriesData.map((item) => item.djl),
        tooltip: {
          valueFormatter: (value) => `${value}次`,
        },
      },
      {
        type: "line",
        name: "日活人数",
        smooth: true,
        symbol: "none",
        data: seriesData.map((item) => item.rhrs),
        tooltip: {
          valueFormatter: (value) => `${value}人`,
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}

// 点击排行 排名图
export function drawDjph(eleId, yData, seriesData, sumList) {
  let option = {
    grid: {
      left: "5%",
      right: "5%",
      bottom: "5%",
      top: "10%",
      containLabel: true,
    },
    xAxis: {
      show: false,
      type: "value",
    },
    yAxis: [
      {
        type: "category",
        inverse: true,
        axisLabel: {
          show: true,
          textStyle: {
            color: "rgba(134, 134, 134, 0.85)",
            fontSize: "14",
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        // data: ["安全门未关", "关模低压保护", "料温过低"],
        data: yData,
      },
      {
        type: "category",
        inverse: true,
        axisTick: "none",
        axisLine: "none",
        show: true,
        axisLabel: {
          textStyle: {
            color: "#303133",
            fontSize: "14",
          },
          formatter: function (value) {
            if (value >= 10000) {
              return (value / 10000).toLocaleString() + "万次";
            } else {
              return value.toLocaleString() + "次";
            }
          },
        },
        data: seriesData,
      },
    ],
    series: [
      {
        name: "",
        type: "bar",
        zlevel: 1,
        itemStyle: {
          normal: {
            barBorderRadius: 16,
            color: "rgba(83, 218, 171, 1)",
          },
        },
        barWidth: 15,
        data: seriesData,
        // label: {
        //   show: true,
        //   formatter: `{c}次`
        // }
      },
      {
        name: "背景",
        type: "bar",
        barWidth: 15,
        barGap: "-100%",
        data: sumList,
        itemStyle: {
          normal: {
            color: "rgba(243, 246, 248, 1)",
            barBorderRadius: 16,
          },
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}

// 事件上报 饼图
export function drawSjsbPie(eleId, seriesData, title) {
  // seriesData[0].value = 1000
  let option = {
    title: title,
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        name: "",
        type: "pie",
        radius: ["45%", "65%"],
        center: ["50%", "50%"],
        avoidLabelOverlap: true,
        label: {
          width: 140,
          overflow: "break",
          textStyle: {
            rich: {
              a: {
                color: "rgba(134,134,134,1)",
                fontSize: 14,
              },
              b: {
                color: "rgba(0,0,0, 1)",
                fontSize: 14,
              },
            },
          },
          formatter: (value) => {
            return `{a|${value.name}}{b|${value.value}}件`;
          },
        },
        labelLine: {
          length: 5,
        },
        data: seriesData,
        tooltip: {
          valueFormatter: (v) => v + "件",
        },
        itemStyle: {
          normal: {
            color: function (colors) {
              var colorList = ["#53DAAB", "#FFCE6F"];
              return colorList[colors.dataIndex];
            },
          },
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}

// 空间预约次数
export function drawYycsLine(eleId, seriesData) {
  let option = {
    color: ["#2063FF", "#E04545"],
    grid: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 10,
      containLabel: true,
    },
    tooltip: {
      show: true,
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgba(0,0,0,0.5)",
        },
      },
      axisTick: {
        inside: true,
        lineStyle: {
          color: "rgba(0,0,0,0.5)",
        },
      },
      data: seriesData.map((item) => item.name),
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgba(0,0,0,0.4)",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "rgba(0,0,0,0.1)",
          type: "dashed",
        },
      },
    },
    series: [
      {
        type: "line",
        name: "空间预约次数",
        smooth: true,
        symbol: "none",
        data: seriesData.map((item) => item.count),
        itemStyle: {
          color: "rgba(255, 206, 111, 1)",
        },
        tooltip: {
          valueFormatter: (value) => `${value}`,
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
// 排名前三
export function drawPmqs(eleId, xData, seriesData, maxList) {
  let option = {
    tooltip: {
      trigger: "axis",
      borderColor: "#62FFFF",
      formatter: " {b}     {c}次",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      x: 65,
      y: 40,
      x2: 20,
      y2: 20,
    },
    xAxis: {
      axisLine: {
        lineStyle: {
          color: "rgba(151, 151, 151, 1)",
        },
      },
      axisLabel: {
        textStyle: {
          color: "rgba(151, 151, 151, 1)",
        },
      },
      show: true,
      type: "value",
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      name: "",
      type: "category",
      data: xData,
      axisLine: {
        lineStyle: {
          color: "rgba(151, 151, 151, 1)",
        },
      },
      axisLabel: {
        textStyle: {
          color: "rgba(151, 151, 151, 1)",
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        name: "",
        type: "bar",
        barWidth: 12,
        data: seriesData,
      },
      {
        name: "",
        type: "bar",
        barWidth: 12,
        barGap: "-100%",
        z: 0,
        itemStyle: {
          barBorderRadius: [0, 20, 20, 0],
          color: "rgba(243, 246, 248, 1)",
        },
        data: maxList,
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
// 积分消费率 今日设备情况
export function drawSdtPie(eleId, value, title) {
  // console.log('value => ', value);
  // value = 50.52;
  let option = {
    // title: {
    //   text: "",
    //   textStyle: {
    //     fontWeight: "normal",
    //     fontSize: 16,
    //     color: "rgba(57, 233, 169, 1)",
    //   },
    // },
    series: [
      {
        type: "liquidFill",
        radius: "50%",
        center: ["50%", "50%"],
        data: [value / 100, value / 100, value / 100], // data个数代表波浪数
        backgroundStyle: {
          borderWidth: 1,
          color: "rgba(133, 255, 216, 0.5)",
        },
        // 修改波浪颜色
        // color:['yellow'], 所有波浪一个颜色
        color: ["#85FFD8", "#30C991", "#30C991"], // 每个波浪不同颜色，颜色数组长度为对应的波浪个数
        label: {
          normal: {
            // formatter: (0.5 * 100).toFixed(2) + "%",
            formatter: () => {
              if (title) {
                return `${value}% \n \n ${title || ""}`;
              } else {
                return value + "%";
              }
            },
            textStyle: {
              fontSize: 16,
              color: "#39E9A9",
            },
          },
        },
        outline: {
          show: false,
        },
      },
      {
        name: "",
        type: "pie",
        startAngle: 80,
        radius: ["80%", "80%"],
        hoverAnimation: false,
        center: ["50%", "50%"],
        itemStyle: {
          color: "rgba(66, 66, 66, .1)",
          borderWidth: 2,
          borderColor: "#30C991",
        },
        data: [100],
      },
      {
        type: "pie",
        center: ["50%", "50%"],
        radius: ["50%", "55%"],
        hoverAnimation: false,
        data: [
          {
            name: "",
            value: value,
            labelLine: {
              show: false,
            },
            itemStyle: {
              color: "#30C991",
            },
            emphasis: {
              labelLine: {
                show: false,
              },
              itemStyle: {
                color: "#30C991",
              },
            },
          },
          {
            //画中间的图标
            name: "",
            value: 2,
            labelLine: {
              show: false,
            },
            itemStyle: {
              color: "#ffffff",
              normal: {
                color: "#fff",
                borderColor: "#30C991",
                borderWidth: 10,
                borderRadius: "100%",
              },
            },
            label: {
              borderRadius: "100%",
            },
            emphasis: {
              labelLine: {
                show: false,
              },
              itemStyle: {
                color: "#30C991",
              },
            },
          },
          // {
          //   // 解决圆点过大后续部分显示明显的问题
          //   name: "",
          //   value: 2,
          //   labelLine: {
          //     show: false,
          //   },
          //   itemStyle: {
          //     color: "#fff",
          //   },
          //   emphasis: {
          //     labelLine: {
          //       show: false,
          //     },
          //     itemStyle: {
          //       color: "#fff",
          //     },
          //   },
          // },
          {
            //画剩余的刻度圆环
            name: "",
            value: 100 - value,
            itemStyle: {
              color: "rgba(133,255,216,0.3)",
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              labelLine: {
                show: false,
              },
              itemStyle: {
                color: "rgba(133,255,216,0.3)",
              },
            },
          },
        ],
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}

// 绘制图表
function drawedChart(eleId, option) {
  let chart = null;
  if (document.getElementById(eleId)) {
    chart = echarts.init(document.getElementById(eleId));
    chart.setOption(option);
  }
  return chart;
}
